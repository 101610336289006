<template>
  <section class="case-detail-container">
    <div class="header-wrapper">
      <h4 class="title">{{ title }}</h4>
      <h5 class="subtitle">{{ subtitle }}</h5>
    </div>

    <div>
      <van-skeleton title :row="5" :loading="loading">
        <article class="markdown-body" v-html="markHtml"></article>
        <van-empty v-if="isError" :description="errorText" />
      </van-skeleton>
    </div>
  </section>
</template>

<script>
import { cmsApi } from '@/utils/cms.js'
import showdown from 'showdown'
import _ from 'lodash'
export default {
  data () {
    return {
      errorText: '暂无数据',
      isError: false,
      loading: true,
      title: '',
      subtitle: '',
      markHtml: ''
    }
  },
  methods: {
    convertMD (data) {
      const converter = new showdown.Converter()
      converter.setOption('tables', true)
      const text = data.toString()
      this.markHtml = converter.makeHtml(text)
    }
  },
  mounted () {
    const id = this.$route.query.id
    this.loading = true
    cmsApi
      .getCaseDetail(id)
      .then(res => {
        this.title = _.get(res, 'data.customerCaseItem[0].name')
        this.subtitle = _.get(res, 'data.customerCaseItem[0].introduction')
        this.convertMD(_.get(res, 'data.customerCaseItem[0].content.html'))
        this.loading = false
      })
      .catch(e => {
        this.isError = true
      })
  }
}
</script>

<style lang="less">
.case-detail-container {
  .markdown-body {
    // width: 100%;
    overflow: hidden;
    font-size: 24px;
    margin: 32px 30px 0;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-top: 24px;
      margin-bottom: 20px;
      font-weight: 600;
      line-height: 1.25;
    }
    h1,
    h2 {
      padding-bottom: 0.5em;
      border-bottom: 1px solid #eaecef;
      font-size: 1.3em;
    }
    h1 {
      font-size: 1.6em;
    }
    ul,
    ol {
      padding-left: 2em;
      list-style-type: disc;
    }
    ul ul {
      list-style-type: circle;
    }
    ul ul ul {
      list-style-type: square;
    }
    ul li {
      line-height: 1.5;
    }
    blockquote,
    details,
    dl,
    ol,
    p,
    pre,
    table,
    ul {
      margin-top: 0;
      margin-bottom: 16px;
    }
    em {
      font-style: normal;
    }
    p {
      line-height: 1.5;
    }
    img {
      max-width: 100%;
      margin: 20px 0;
    }
    pre {
      padding: 20px;
      overflow: auto;
      line-height: 1.45;
      background-color: #f6f8fa;
      border-radius: 3px;
    }
    table {
      display: block;
      width: 690px;
      overflow: auto;
      thead tr {
        background-color: #f6f8fa;
      }
      tr {
        &:nth-child(even) {
          background-color: #f6f8fa;
        }
      }
      td,
      th {
        padding: 15px 15px;
      }
      td {
        line-height: 32px;
      }
    }
  }
  .header-wrapper {
    padding: 32px 0;
    margin: 0 30px;
    border-bottom: 1px solid rgba(0,0,0,0.06);;
    .title {
      font-size: 36px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 52px;
    }
    .subtitle {
      font-size: 28px;
      margin-top: 8px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.45);
      line-height: 44px;
    }
  }
}
</style>
